$bg-img: url("../assets/images/background.png");
$color-primary: #003157;
.content {
  background-color: red;
  display: flex;
  flex-direction: column;
}
.logo {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 0px;
}
.logo-header {
  height: 100%;
  width: 100px;
  object-fit: cover;
}

.site-layout .site-layout-background {
  background: #fff;
  height: 100%;
  background-image: $bg-img !important;
  background-size: cover;
}
.ant-layout-content {
  background-image: $bg-img !important;
  background-size: cover;


}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: rgb(169, 184, 195) !important;
}

.ant-layout-sider-children {
  background-color: $color-primary;
  .ant-menu {
    background: $color-primary !important;
  }
  .ant-menu-item {
    height: 50px !important;
    padding-left: 5px !important;
  }

  .ant-menu-item-selected {
    background-color: #f58a07 !important;
  }
  .ant-menu-submenu-title {
    padding-left: 0 !important;
    margin:4px
  }
  .ant-menu-item-only-child {
    padding-left: 40px !important;
  }
}
.ant-btn-primary {
  background-color: #f58a07 !important;
  border-color: #f58a07 !important
}

.ant-btn-dangerous {
  background-color: #D01E29 !important;
}
.ant-modal-header {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  /* Only border-radius here */
}


.ant-modal-content {
  border-radius: 8px !important;
}
// .ant-table-thead {
//   background-color: #003157 !important;
// }
.ant-table-thead .ant-table-cell {
  background-color: #C1CBD2;
}
.ant-modal-title {
  color: $color-primary !important
}

.dashboard {
  background-color: #367fa9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
}
.divider {
  background-color: #367fa9;
  height: 2px;
  width: 100%;
  margin: 5px 0px;
}

.menu-icon {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
.contract {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .info {
    background-color: #0066B5;
    width: 95%;
    border-radius: 6px;
    padding: 5px 2px;
  }
}

.table-row-light {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 49, 87, 0.25);

}
.table-row-dark {
  background-color: #EFD7C9 ;
  box-shadow: 16px rgba(0, 49, 87, 0.25) !important;
}

.principal-document-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.principal-document-container .principal-document-container-link {
  color: white;
  font-size: 12px;
}

.cs-contact-container {
  text-align: center;
}

.contact-cs-modal-success-img-container {
  text-align: center;
}

.contact-cs-modal-success-img {
  width: 40px;
  height: 40px;
}

.contact-cs-modal-success-text {
  color: #6E6E6E;
  text-align: center;
}
