.text-white {
  color: white !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.center {
  text-align: center;
}

.p-0 {
  padding: 0px;
}

.p-10 {
  padding: 10px;
}

.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}


.d-flex {
  display: flex;
}
.jcenter {
  justify-content: center;
}